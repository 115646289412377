import React from "react";

function Person(props) {
  return (
    <section className="person" id={props.name}>
      <article className="profile">
        <img alt={props.name} src={`./assets/${props.name}.webp`} />
        <h6>{props.name}</h6>
        <p>
          {props.occupation}
        </p>
      </article>

      <article className="socialIcon">
        { props.children }
      </article>
    </section>
  );
}

export default Person;
