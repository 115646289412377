import Logo from './components/Logo';
import Person from './components/Person';
import SocialIcon from './components/SocialIcon';
import './App.css';

function App() {
  return (
    <>
      <main>
        <section className="logo">
          <Logo />
        </section>
        <Person name="oct216" occupation="Engineer / Designer">
          <SocialIcon social="github" username="oct216" />
        </Person>
        <Person name="drafly75" occupation="Engineer">
          <SocialIcon social="github" username="drafly75" />
        </Person>
      </main>
    </>
  );
}

export default App;
