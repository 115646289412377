import React from "react";

function Logo(props) {
  return (
    <svg width="629" height="181" viewBox="0 0 629 181" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style ? props.style : undefined}>
      <circle cx="387.68" cy="129.602" r="17.9124" fill="#FF9900" />
      <path
        d="M588.245 147.686C583.38 147.686 579.157 146.949 575.577 145.476C571.997 144.003 569.014 141.931 566.628 139.261C564.333 136.591 562.589 133.461 561.395 129.87C560.202 126.187 559.605 122.136 559.605 117.716C559.605 112.192 560.477 106.668 562.222 101.143C564.057 95.6191 566.719 90.6472 570.207 86.2278C573.696 81.8083 577.964 78.2175 583.012 75.4553C588.061 72.6011 593.844 71.174 600.361 71.174C605.134 71.174 609.311 71.9105 612.891 73.3837C616.562 74.8568 619.546 76.9285 621.84 79.5986C624.227 82.2687 626.017 85.4451 627.21 89.128C628.403 92.7189 629 96.724 629 101.143C629 106.668 628.128 112.192 626.384 117.716C624.64 123.241 622.07 128.213 618.674 132.632C615.277 137.052 611.009 140.688 605.869 143.543C600.82 146.305 594.945 147.686 588.245 147.686ZM598.433 88.5756C595.404 88.5756 592.742 89.4503 590.448 91.1997C588.153 92.949 586.225 95.1588 584.665 97.8289C583.104 100.499 581.911 103.445 581.085 106.668C580.35 109.798 579.983 112.791 579.983 115.645C579.983 120.341 580.718 123.977 582.186 126.555C583.655 129.041 586.317 130.284 590.172 130.284C593.201 130.284 595.863 129.41 598.158 127.66C600.453 125.911 602.38 123.701 603.941 121.031C605.501 118.361 606.649 115.461 607.383 112.33C608.209 109.108 608.622 106.069 608.622 103.215C608.622 98.5194 607.888 94.9286 606.419 92.4426C604.951 89.8646 602.289 88.5756 598.433 88.5756Z"
        fill="#FF9900" />
      <path
        d="M513.512 147.686C508.647 147.686 504.424 146.949 500.844 145.476C497.264 144.003 494.281 141.931 491.895 139.261C489.6 136.591 487.856 133.461 486.663 129.87C485.469 126.187 484.873 122.136 484.873 117.716C484.873 112.192 485.745 106.668 487.489 101.143C489.324 95.6191 491.986 90.6472 495.475 86.2278C498.963 81.8083 503.231 78.2175 508.279 75.4553C513.328 72.6011 519.111 71.174 525.628 71.174C530.401 71.174 534.578 71.9105 538.158 73.3837C541.829 74.8568 544.813 76.9285 547.107 79.5986C549.494 82.2687 551.284 85.4451 552.477 89.128C553.671 92.7189 554.267 96.724 554.267 101.143C554.267 106.668 553.395 112.192 551.651 117.716C549.907 123.241 547.337 128.213 543.941 132.632C540.544 137.052 536.276 140.688 531.136 143.543C526.087 146.305 520.212 147.686 513.512 147.686ZM523.7 88.5756C520.671 88.5756 518.009 89.4503 515.715 91.1997C513.42 92.949 511.492 95.1588 509.932 97.8289C508.371 100.499 507.178 103.445 506.352 106.668C505.618 109.798 505.25 112.791 505.25 115.645C505.25 120.341 505.985 123.977 507.453 126.555C508.922 129.041 511.584 130.284 515.439 130.284C518.468 130.284 521.13 129.41 523.425 127.66C525.72 125.911 527.648 123.701 529.208 121.031C530.768 118.361 531.916 115.461 532.65 112.33C533.476 109.108 533.889 106.069 533.889 103.215C533.889 98.5194 533.155 94.9286 531.686 92.4426C530.218 89.8646 527.556 88.5756 523.7 88.5756Z"
        fill="#FF9900" />
      <path
        d="M495.005 0C500.62 0 505.486 0.623847 509.603 1.87154C513.845 2.99447 517.027 4.05501 519.148 5.05317L509.79 26.9502C507.544 25.8273 504.924 25.0163 501.93 24.5172C499.06 23.8934 496.378 23.5814 493.882 23.5814C487.02 23.5814 481.904 25.3282 478.536 28.8218C475.292 32.3153 473.046 36.6823 471.798 41.9226L470.675 46.7886H503.614L498 69.6214H465.248L453.831 117.346C449.589 135.188 444.972 149.037 439.982 158.894C434.991 168.876 429.501 176.112 423.512 180.604L398.621 177.048C401.865 174.054 404.859 170.373 407.604 166.006C410.349 161.639 412.782 157.023 414.903 152.156C417.149 147.29 419.02 142.362 420.518 137.371C422.14 132.256 423.45 127.515 424.448 123.148L443.912 41.5483C447.031 28.3227 452.646 18.0916 460.756 10.8549C468.866 3.61832 480.282 0 495.005 0Z"
        fill="white" />
      <path
        d="M398.58 70.7443C396.084 70.1205 393.589 69.559 391.094 69.0599C388.723 68.4361 385.167 68.1241 380.426 68.1241C378.679 68.1241 376.745 68.3113 374.624 68.6856C372.503 68.9351 370.756 69.2471 369.384 69.6214L351.23 145.232H323.344L345.802 52.0289C351.167 50.2821 357.156 48.6601 363.769 47.1629C370.507 45.5409 378.055 44.7299 386.415 44.7299C389.534 44.7299 392.965 45.0418 396.708 45.6656C400.576 46.1647 403.945 47.0381 406.815 48.2858L398.58 70.7443Z"
        fill="white" />
      <path
        d="M309.679 107.951C308.152 114.19 307.715 120.482 308.37 126.83C309.025 133.068 310.279 138.649 312.134 143.574L290.37 146.693C289.715 145.38 289.115 144.121 288.57 142.917C288.024 141.714 287.479 140.346 286.933 138.813C283.879 141.33 280.497 143.41 276.788 145.051C273.079 146.693 268.933 147.514 264.351 147.514C258.897 147.514 254.206 146.584 250.278 144.723C246.46 142.753 243.296 140.181 240.787 137.008C238.387 133.724 236.642 129.949 235.551 125.681C234.46 121.303 233.915 116.652 233.915 111.727C233.915 104.176 235.224 97.0623 237.842 90.3865C240.569 83.7107 244.278 77.9104 248.969 72.9856C253.769 67.9514 259.388 64.0116 265.824 61.1662C272.26 58.3208 279.242 56.8981 286.77 56.8981C287.752 56.8981 289.442 56.9528 291.843 57.0622C294.352 57.1717 297.188 57.4453 300.352 57.883C303.515 58.2113 306.843 58.8133 310.334 59.6888C313.825 60.5643 317.152 61.7681 320.316 63.3003L309.679 107.951ZM292.661 77.7463C291.243 77.5274 289.933 77.3632 288.733 77.2538C287.642 77.1443 286.279 77.0896 284.642 77.0896C280.933 77.0896 277.442 78.0199 274.17 79.8803C271.006 81.7408 268.224 84.2032 265.824 87.2675C263.424 90.2223 261.515 93.6697 260.097 97.6095C258.788 101.549 258.133 105.653 258.133 109.921C258.133 115.174 259.006 119.333 260.751 122.397C262.497 125.462 265.715 126.994 270.406 126.994C272.915 126.994 275.151 126.501 277.115 125.516C279.188 124.532 281.37 122.89 283.661 120.592C283.879 117.856 284.206 115.01 284.642 112.055C285.188 108.991 285.733 106.2 286.279 103.683L292.661 77.7463Z"
        fill="white" />
      <path
        d="M169.257 94.8145C167.185 99.3043 164.841 103.904 162.224 108.612C159.717 113.212 157.1 117.756 154.374 122.246C151.757 126.626 149.195 130.842 146.688 134.894C144.18 138.836 141.945 142.341 139.982 145.407H121.011C120.138 140.807 119.212 135.332 118.23 128.981C117.358 122.629 116.486 115.73 115.614 108.284C114.741 100.728 113.924 92.7886 113.16 84.4661C112.506 76.0341 112.016 67.5473 111.688 59.0057H135.566C135.566 62.948 135.566 67.2188 135.566 71.8181C135.675 76.4174 135.784 81.1809 135.893 86.1087C136.111 91.0365 136.384 96.0739 136.711 101.221C137.038 106.368 137.474 111.46 138.02 116.497C140.745 111.898 143.417 107.134 146.033 102.206C148.759 97.1689 151.321 92.1864 153.72 87.2586C156.228 82.2212 158.517 77.2934 160.589 72.4751C162.77 67.6568 164.678 63.167 166.313 59.0057H186.593C186.593 66.8902 186.702 75.7056 186.92 85.4517C187.247 95.1978 187.956 105.546 189.046 116.497C191.99 111.569 194.716 106.532 197.224 101.385C199.731 96.1286 202.021 90.9818 204.092 85.9445C206.273 80.9071 208.181 76.0888 209.817 71.4895C211.561 66.8902 213.088 62.729 214.396 59.0057H240.236C238.056 64.4811 235.439 70.6682 232.386 77.5672C229.442 84.3566 226.008 91.5293 222.083 99.0853C218.158 106.641 213.742 114.362 208.835 122.246C203.929 130.131 198.477 137.851 192.481 145.407H173.509C172.201 136.427 171.22 127.831 170.565 119.618C169.911 111.405 169.475 103.137 169.257 94.8145Z"
        fill="white" />
      <path
        d="M34.9847 147.514C28.7486 147.514 23.3855 146.456 18.8955 144.34C14.5302 142.223 10.9132 139.36 8.04461 135.75C5.30071 132.016 3.24279 127.721 1.87084 122.866C0.623614 117.887 0 112.596 0 106.994C0 98.5294 1.49667 90.5624 4.49002 83.0933C7.60808 75.4998 11.8487 68.9021 17.2117 63.3003C22.5748 57.6985 28.9357 53.2793 36.2943 50.0427C43.6529 46.8061 51.6975 45.1878 60.4281 45.1878C62.6731 45.1878 64.7311 45.3123 66.6019 45.5613C68.5975 45.8102 70.593 46.1837 72.5886 46.6816L82.6911 4.48143L111.689 0L86.994 102.513C85.2479 109.608 84.749 116.766 85.4974 123.986C86.2457 131.082 87.68 137.431 89.8003 143.033L64.9181 146.58C64.1698 145.087 63.4838 143.655 62.8602 142.286C62.2366 140.916 61.613 139.36 60.9894 137.617C57.4971 140.481 53.5684 142.846 49.2031 144.713C44.9625 146.58 40.2231 147.514 34.9847 147.514ZM67.1631 68.9021C66.4148 68.7776 65.7288 68.6531 65.1052 68.5286C64.6063 68.4041 64.0451 68.3419 63.4215 68.3419C62.9226 68.2174 62.2366 68.1552 61.3635 68.1552C60.4905 68.1552 59.368 68.1552 57.996 68.1552C53.6307 68.1552 49.5773 69.2133 45.8356 71.3295C42.0939 73.3213 38.8511 76.0599 36.1072 79.5455C33.488 83.0311 31.4301 87.0146 29.9334 91.496C28.4368 95.853 27.6884 100.334 27.6884 104.94C27.6884 110.916 28.6862 115.646 30.6818 119.132C32.8021 122.493 36.5437 124.173 41.9068 124.173C44.7754 124.173 47.3946 123.613 49.7643 122.493C52.1341 121.372 54.6285 119.505 57.2477 116.891C57.4971 113.779 57.8713 110.542 58.3702 107.181C58.9938 103.695 59.6174 100.521 60.241 97.658L67.1631 68.9021Z"
        fill="white" />
    </svg>
  );
}

export default Logo;
