import React from "react";

const socialSwitcher = (social, username) => {
  switch(social) {
    case 'github':
      return {
        href: `https://github.com/${username}`,
        src: './assets/github-mark-white.svg'
      };
    case 'bluesky':
      return {
        href: `https://bsky.app/profile/${username}`,
        src: './assets/bluesky_media_kit_logo_transparent_2.png'
      };
    default:
      return (
        <></>
      );
  }
};

function SocialIcon(props) {
  const socialInfo = socialSwitcher(props.social, props.username);

  return (
    <a
      href={socialInfo.href}
      rel="noreferrer"
      target="_blank"
      title={props.social}
    >
      <img alt={props.social} src={socialInfo.src} />
    </a>
  );
}

export default SocialIcon;
